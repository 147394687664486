import React, { useEffect, useState } from "react"
import Layout from "../components/global/layout"
import Pumpd from "../components/pumpd/pumpd"
import Footer from "../components/footer/footer"

const PumpdPromo = () => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }
  }, [])
  return (
    <Layout isDarkLogo isNotHome noButton yPos={offset}>
      <Pumpd />
      <Footer />
    </Layout>
  )
}

export default PumpdPromo
