import React, { useState, useEffect } from "react"
import style from "./form.mod.scss"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import ArrowRightBlack from "../icons/arrow-right-black"
import { graphql } from "gatsby"

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.number().required(),
  company: yup.string().required(),
  optin: yup.boolean(),
})

const Form = ({ locations }) => {
  const pageUrl = typeof window !== "undefined" ? window.location.href : ""
  const [pageTitle, setPageTitle] = useState("")
  const [submitStatus, setSubmitStatus] = useState("Send")
  const [submitted, setSubmitted] = useState(false) // New state variable
  const [error, setError] = useState(false) // New state variable

  useEffect(() => {
    setPageTitle(document.title)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const onSubmitHandler = (data) => {
    data.pageUrl = pageUrl
    data.pageTitle = pageTitle

    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    var raw = JSON.stringify(data)


    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    }

    fetch(
      "https://hook.us1.make.com/hrc36kv89hv3ky5xo61x9skgq3issy5u",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (result === "Accepted") {
          setSubmitStatus("Message Sent")
          setSubmitted(true) // Update the submitted state
        } else {
          setSubmitStatus("An error occurred")
          // handle error here
          setError(true)
        }
      })
      .catch((error) => {
        setSubmitStatus("An error occurred")
        // handle error here
        setError(true)
      })
  }

  return (
    <>
      <div className={style.form}>
        {!submitted && !error && (
          <form
            onSubmit={handleSubmit(onSubmitHandler)}
            name="contactForm"
            method="POST"
          >
            <input
              {...register("name")}
              placeholder="Name*"
              type="text"
              required
            />
            {/* <p>{errors.email?.message}</p> */}
            <input
              {...register("email")}
              placeholder="Email*"
              type="email"
              required
            />
            <input
              {...register("phone")}
              placeholder="Phone*"
              type="number"
              required
            />
            <input
              {...register("company")}
              placeholder="Company*"
              type="text"
              required
            />
            <Checkbox
              name={"optin"}
              value={"optin"}
              register={{ ...register("optin") }}
            />

            <button className={style.form__button} type="submit">
              <div className={style.form__submit}>
                <span>Submit</span>
                <ArrowRightBlack />
              </div>
            </button>
          </form>
        )}
        {submitted && (
          <div>
            <p className={style.form__text}>
              {
                "Thank you for entry. We will be in touch with the winners shortly."
              }
            </p>
          </div>
        )}
        {error && (
          <div>
            <p className={style.form__text}>
              {"There seems to be an issue, please contact us."}
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default Form

export const query = graphql`
  {
    allDatoCmsContactPage {
      nodes {
        locations {
          title
        }
      }
    }
  }
`
const Checkbox = ({ name = "", register }) => {
  const classes = [style.checkbox]
  const [checked, setChecked] = useState(true)
  checked && classes.push(style[`checkbox--selected`])

  const onChange = () => {
    setChecked(!checked)
  }

  return (
    <div className={[...classes].join(" ")}>
      <label>
        {checked ? <Checked /> : <Check />}
        Interested in staying informed without the clutter? <br />
        Subscribe to our newsletter for curated updates from Harvey Cameron!
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onClick={onChange}
          {...register}
        />
      </label>
    </div>
  )
}

const Check = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M384 80c8.8 0 16 7.2 16 16v320c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16h320zM64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
  </svg>
)

const Checked = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path d="M64 80c-8.8 0-16 7.2-16 16v320c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96c0-35.3 28.7-64 64-64h320c35.3 0 64 28.7 64 64v320c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm337 113L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
  </svg>
)
